<template>
<section class="domain_content">
    <!-- About Us -->
    <div class="sub-page">
        <h1>About Us</h1>
        <p>
            Welcom to gbwhatsapk.chat. Here we provide detail guide about using GB WhatsApp.
        </p>
        <p>
            Thanks for visiting <a href="/">https://gbwhatsapk.chat/</a>. We aim to provide users with safe GB WhatsApp APK and other modified version of WhatsApp. In this site, you can get latest or older version of GB WhatsApp, you can also get new information about this app.
        </p>
        <p>
            If you have any questions, you can also look through our content to find a solution.
        </p>
        <p>
            Have a nice day!
        </p>
    </div>
</section>
</template>

  
  
<script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'Welcome to gbwhatsapk.chat. Here we provide detail guide about using GB WhatsApp.'
export default {
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description,
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
        "link": [{
            "rel": "canonical",
            "href": "https://gbwhatsapk.chat/about-us/"
        }]
    },
}
</script>
